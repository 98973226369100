import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import logo from '../assets/media/images/logo.png'
import homepageImage from '../assets/media/images/homepage.jpg'


const TimeDisplay = () => {
  const [time, setTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      let hours = now.getHours();
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const formattedHours = String(hours).padStart(2, '0');

      setTime(`${formattedHours}:${minutes}:${seconds} ${ampm}`);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='w-32 py-3 rounded-3xl bg-green-800 text-white text-xs font-medium inline-flex items-center justify-center gap-1 tracking-wider box-border'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 size-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
      {time}
    </div>
  );
};

const GreetingButton = () => {
  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return (
        <span className='inline-flex items-center gap-1'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 w-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
          </svg>
          Good Morning, Visitor!
        </span>
      );
    } else if (currentHour < 18) {
      return (
        <span className='inline-flex items-center gap-1'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
          </svg>
          Good Afternoon, Visitor!
        </span>
      );
    } else {
      return (
        <span className='inline-flex items-center gap-1'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
          </svg>
          Good Evening, Visitor!
        </span>
      );
    }
  };

  return (
    <button className='bg-green-800 px-4 py-3 rounded-xl border-none outline-none text-white font-bold inline-flex items-center gap-2'
      aria-label="Greeting button">
      {getGreeting()}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
        />
      </svg>
    </button>
  );
};

export default function Home() {

  return (
    <div className='bg-[#F4F5F0]'>
      <div className='container mx-auto flex flex-col px-4 xl:px-0 py-2'>
        <div className='flex items-center justify-between'>
          <Link to='https://www.gu-wi.com/'><img src={logo} alt="" className='w-12' /></Link>
          <TimeDisplay />
        </div>
        <div className='flex-1 grid grid-cols-1 xl:grid-cols-2 gap-12 xl:gap-6 items-center justify-center mt-12 xl:mt-28'>

          {/* Left Section */}
          <div className='w-full flex flex-col gap-4 xl:gap-6 items-center xl:items-start'>
            <h1 className='text-black text-4xl xl:text-7xl font-extrabold text-center xl:text-start'>
              Gu-Wi Advanced Quote Builder Tool
            </h1>
            <p className='text-xs xl:text-sm text-gray-600 max-w-xs xl:max-w-lg text-center xl:text-start'>
              The Gu-Wi Quote Builder lets you customize window and gutter cleaning services, tailored specifically to your needs and location for a truly personalized experience.
            </p>
            <GreetingButton />
          </div>

          {/* Right Login Section */}
          <div className='w-full h-full flex items-center justify-center'>
            <div className='w-full flex flex-col gap-6 items-center p-4'>
              <img src={homepageImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
